$(document).ready(function () {
    $(document).on('click', '.add-to-cart-trigger', addItemToCart);
    $(document).on('click', '.add-to-cart-from-wish-list', addItemToCartFromWishList);
    $(document).on('click', '.add-to-wish-list-trigger', addToWishList);
    $(document).on('click', '.delete-from-wish-list', deleteFromWishList);
    $(document).on('click', '.delete-item-from-cart-trigger', deleteFromCart);

    var remainingMinutes = $('#remaining_minutes');
    if (remainingMinutes.length) {
        var remainingSeconds = remainingMinutes.data('remaining_seconds');
        startTimer(remainingSeconds, remainingMinutes);
    }

    function startTimer(duration, display) {
        var timer = duration, minutes, seconds;
        var interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            if (--timer < 0) {
                clearInterval(interval);
                window.location.href = "/checkout/cancel-order";
            } else {
                display.text(minutes + ":" + seconds);
            }
        }, 1000);
    }

    function deleteFromWishList(evt) {
        var item = $(evt.target).closest('tr.CartProduct');
        $.ajax(baseUrl + "users/wish-list/" + item.data('id'), {
            type: "DELETE",
            success: function (data) {
                location.reload();
            }
        });
    }

    function addToWishList() {
        var data = {
            product_id: $("#product_id").val(),
            option_id: $("#option_id").val(),
            has_combinations: $("#has_combinations").val()
        };

        $.ajax(baseUrl + 'users/wish-list', {
            data: data,
            method: "POST",
            dataType: 'json',
            success: function (response) {
                showMessage(response.message);
            },
            error: needLoginOrOrderIsConfirmed
        });
    }

    function reloadShoppingCart(data) {
        if(data.error_msg) {
            showError(data.error_msg);
        } else {
            showMessage(data.message);
        }

        $.getJSON(baseUrl + "shopping-cart", function (data) {
            $('#shopping-cart-list').html(data.desktop);
            $('#shopping-cart-list-mobile').html(data.mobile);
            $('#shopping-cart-total-mobile').html(data.total_in_cart);
            initCartScrollBar();
        });
    }

    function deleteFromCart(evt) {
        var item = $(evt.target).closest('.delete-item-from-cart-trigger');
        $.ajax(baseUrl + "shopping-cart/" + item.data('id'), {
            type: "DELETE",
            dataType: "json",
            success: function (data) {
                reloadShoppingCart(data);

                if (item.data('reload') != undefined) {
                    location.reload();
                }
            },
            error: needLoginOrOrderIsConfirmed
        });
    }

    function addItemToCart() {
        var data = {
            product_id: $("#product_id").val(),
            option_id: $("#option_id").val(),
            quantity: $("#quantity").val(),
            has_combinations: $("#has_combinations").val()
        };
        $.ajax(baseUrl + 'shopping-cart', {
            data: data,
            method: "POST",
            dataType: 'json',
            success: reloadShoppingCart,
            error: needLoginOrOrderIsConfirmed
        });
    }

    function addItemToCartFromWishList(evt) {
        var item = $(evt.target).closest('tr.CartProduct');
        var data = {
            product_id: item.data("product_id"),
            option_id: item.data("option_id"),
            quantity: item.data("quantity")
        };

        $.ajax(baseUrl + 'shopping-cart', {
            data: data,
            method: "POST",
            dataType: 'json',
            success: reloadShoppingCart,
            error: needLoginOrOrderIsConfirmed
        });
    }

    function reloadShoppingCartAndPage(data) {
        showMessage(data.message);
        window.location.reload();
    }

    function needLoginOrOrderIsConfirmed(data) {
        var response = data.responseJSON;
        if (response && response.status == 401) {
            location.href = baseUrl + "login?redirect=products/" + $('#product_id').val();
        } else if (response && response.status == 403 && response.order_confirmed) {
            location.href = response.redirect;
        }
    }
});