$(document).ready(function () {
    $(document).on('change', '#country', changeStateIntoDropdown);

    function changeStateIntoDropdown(evt) {
        var selected = $('#country').val();
        var state = $('#state');
        if (selected == "us") {
            state = state.replaceWith('<select name="state" id="state" class="form-control"></select>');
            $('#state').append($('<option>', {
                value: "",
                text: "State"
            }));
            var usStates = $.parseJSON($('#us_states').html());
            $.each(usStates, function (val, text) {
                $('#state').append($('<option>', {
                    value: val,
                    text: text
                }));
            });
        } else {
            state.replaceWith($('<input/>', {'type': 'text', 'name': 'state', 'id': 'state', 'class': 'form-control'}));
        }
    }
});